import './App.css';
import Logo from './SVG/logo'
function App() {
  return (
    <div className="App">
      <header className="App-header">
       <Logo />
       <p className="flink"><a href="https://www.facebook.com/macjopherlyn/">Join us on facebook</a></p>
      </header>
    </div>
  );
}

export default App;
